import aboutuslist from './apiAboutus';
import { ABOUT_US_DATA } from '../types';

const initialState = [];

export const aboutusLists = () => async (dispatch) => {
  dispatch({
    type: ABOUT_US_DATA,
    payload: aboutuslist,
  });
};

const aboutusReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_US_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default aboutusReducer;
