import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { homeLists } from '../../../redux/home_block/homeActions';
import Services from './Services';
import Footer from './Footer';
// import HomeItem from './HomeItem';
import './favyt.css';
import HowItWorks from './HowItWorks';

const Home = () => {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.Home);
  useEffect(() => {
    if (home.length === 0) {
      dispatch(homeLists());
    }
  });
  return (
    <div>
      <div className="hero-section">
        <Container className="text-center">
          <h1>Welcome to Favyt Technology</h1>
          <p>Elevate Your Online Presence with Your Favourite Partner in Tech Solutions</p>
          <Button variant="primary" href="#services">
            Explore Services
          </Button>
        </Container>
      </div>
      <Services />
      <HowItWorks />
      {/* <h2 className="text-center mb-4">Our Team</h2>
      <ul className="exchange-list-conatiner">
        <div className="card_container">
          {home.map((home) => (
            <HomeItem
              key={home.mobile}
              home={home}
            />
          ))}
        </div>
      </ul> */}
      <Footer />
    </div>
  );
};

export default Home;
