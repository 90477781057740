/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Container, Row,
} from 'react-bootstrap';
import './HowItWorks.css';
import ComprehensiveITServices from './images/Comprehensive IT Services.jpeg';
import ExpertConsultancy from './images/Expert Consultancy.jpeg';
import InnovativeSolutions from './images/Innovative Solutions.jpeg';

const HowItWorks = () => {
  const works = [
    {
      title: 'Comprehensive IT Services',
      content: 'We offer a wide range of IT services including Web-App Development, E-commerce Solutions, App Development, and UI/UX design to meet all your digital needs.',
      imageUrl: ComprehensiveITServices,
    },
    {
      title: 'Innovative Solutions',
      content: 'Our team is dedicated to crafting innovative and customized digital solutions that will elevate your online presence and drive success for your business.',
      imageUrl: ExpertConsultancy,
    },
    {
      title: 'Expert Consultancy',
      content: 'We provide expert consultancy to help you make informed decisions about technology, ensuring that your business stays ahead in the digital landscape.',
      imageUrl: InnovativeSolutions,
    },
  ];
  return (
    <Container id="howitworks" className="my-5">
      <h2 className="text-center mb-4">How it works</h2>
      <Row className="work-card_container">
        {works.map((work, index) => {
          const isOdd = index % 2 === 0;
          const cardClass = isOdd ? 'odd-card' : 'even-card';
          return (
            <div key={index} className="card-container">
              <div className={`card-content ${cardClass}`}>
                {isOdd ? (
                  <>
                    <div className="card-image ">
                      <img src={work.imageUrl} alt="Card" />
                    </div>
                    <div className="card-text">
                      <h2>{work.title}</h2>
                      <p>{work.content}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card-text">
                      <h2>{work.title}</h2>
                      <p>{work.content}</p>
                    </div>
                    <div className="card-image">
                      <img src={work.imageUrl} alt="Card" />
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </Row>
    </Container>
  );
};

export default HowItWorks;
