import homelist from './homeAPI';
import { HOME_DATA } from '../types';

export const homeLists = () => async (dispatch) => {
  dispatch({
    type: HOME_DATA,
    payload: homelist,
  });
};

export default homeLists;
