import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => (
  <footer className="mt-5">
    <Container className="text-center">
      <p>
        &copy;
        {new Date().getFullYear()}
        {' '}
        Favyt Technology
      </p>
    </Container>
  </footer>
);

export default Footer;
