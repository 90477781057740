const Aboutus = [
  {
    firstName: 'Neeraj',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999999',
    email: 'care@favyt.com',
  },
  {
    firstName: 'Aaruhi',
    lastName: 'Sharma',
    address: 'Pune',
    mobile: '9999999998',
    email: 'care@favyt.com',
  },
  {
    firstName: 'Akshaj',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999997',
    email: 'care@favyt.com',
  },
];

export default Aboutus;
