/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { aboutusLists } from '../../redux/aboutus_block/Aboutus';
// import AboutusItem from './AboutusItem';
import './Aboutus.css';

const Aboutus = () => {
  const dispatch = useDispatch();
  const aboutus = useSelector((state) => state.Aboutus);
  useEffect(() => {
    if (aboutus.length === 0) {
      dispatch(aboutusLists());
    }
  });

  return (
    <div>
      <div className="about-us about">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="section-heading homepage-heading">Favyt Technology</h2>
              <p>
                Welcome to Favyt Technology, where innovation meets excellence! At Favyt,
                we are passionate about crafting digital solutions that elevate your online
                presence and drive success for your business. As a leading provider of
                comprehensive IT services, we specialize in Web-App Development,
                E-commerce Solutions, App Development, UI/UX Design, and SEO.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h3 className="section-subheading homepage-heading">Our Vision</h3>
              <p>
                At Favyt Technology, our vision is to empower businesses with cutting-edge digital
                solutions that not only meet but exceed their expectations. We believe in leveraging
                technology to transform
                ideas into reality, creating a digital landscape that is both dynamic and impactful.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <br />
              <h3 className="section-subheading homepage-heading">What Sets Us Apart</h3>
              <ul>
                <li>
                  <strong>Holistic Approach:</strong>
                  {' '}
                  We take a holistic approach to digital transformation, considering every aspect
                  of your business to provide integrated solutions that deliver results.
                </li>
                <br />
                <li>
                  <strong>Innovation at the Core:</strong>
                  {' '}
                  Innovation is at the heart of everything we do. Our team of skilled professionals
                  constantly explores new technologies and methodologies to ensure that your
                  business stays ahead in the digital realm.
                </li>
                <br />
                <li>
                  <strong>Client-Centric Focus:</strong>
                  {' '}
                  Your success is our success. We prioritize understanding your unique needs and
                  objectives, tailoring our services to align with your vision and goals.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <ul className="exchange-list-conatiner">
        <h1 className="homepage-heading">Our Team</h1>
        <div className="card_container">
          {aboutus.map((aboutus) => (
            <AboutusItem
              key={aboutus.mobile}
              aboutus={aboutus}
            />
          ))}
        </div>
      </ul> */}
    </div>
  );
};

export default Aboutus;
