import { REGISTER_CONTACT_US_SUCCESS, REGISTER_CONTACT_US_FAIL } from '../types';

const initialState = [];

const contactUsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_CONTACT_US_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case REGISTER_CONTACT_US_FAIL:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

export default contactUsReducer;
