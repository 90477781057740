import axios from 'axios';
import { API_URL } from '../apis';
// import authHeader from '../../';

const contactUsAPI = async (contactUsDetails) => axios.post(`${API_URL}contact_us_block/contact_us/message`,
  {
    data: {
      attributes:
  {
    name: contactUsDetails.name,
    email: contactUsDetails.email,
    mobile: contactUsDetails.mobile,
    message: contactUsDetails.message,
    isGuest: contactUsDetails.isGuest,
  },
    },
  }, { headers: { token: `${'authHeader().token'}` } });

export default contactUsAPI;
