import React from 'react';
import {
  Route, Routes, Link,
} from 'react-router-dom';
import Home from './components/home_block/favyt/Home';
import Aboutus from './components/aboutus_block/Aboutus';
import ContactUs from './components/contact_us_block/ContactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import image from './components/home_block/favyt/Favyt_Logo.png';

const App = () => (
  <div>
    <nav className="navbar navbar-expand navbar-dark bg-primary">
      <Link to="/" className="navbar-brand">
        <img
          className="d-inline-block align-top"
          width="50"
          height="30"
          src={image}
          alt="Favyt Technology"
        />
      </Link>
      <div className="navbar-nav collapse navbar-collapse">
        <div className="navbar-nav collapse navbar-collapse">
          <li className="nav-item">
            <Link to="/home" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/aboutus" className="nav-link">
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contactus" className="nav-link">
              Conatct Us
            </Link>
          </li>
        </div>
      </div>
    </nav>

    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/aboutus" element={<Aboutus />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="*" element={<Home />} />
      </Routes>
    </div>
  </div>
);

export default App;
