/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import registerContactUs from '../../redux/contact_us_block/contactUsActions';
import './ContactUs.css';

const ContactUs = () => {
  const { isLoggedIn } = '0';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeMobile = (e) => {
    const mobile = e.target.value;
    setMobile(mobile);
  };

  const onChangeMessage = (e) => {
    const message = e.target.value;
    setMessage(message);
  };

  const handleConactUs = (event) => {
    event.preventDefault();
    setLoading(true);
    const contactUsDetails = {
      name,
      email,
      mobile,
      message,
      isGuest: !isLoggedIn,
    };
    dispatch(registerContactUs(contactUsDetails))
      .then(() => {
        setLoading(false);
        navigate('/');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <section className="contact-us-section" id="contactus">
      <div className="contact-us-container">
        <h2>Contact Us</h2>
        <p>Have questions or need assistance? Feel free to reach out to us!</p>
        <form className="contact-form" onSubmit={handleConactUs}>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Label>Your Name:</Form.Label>
              <Form.Control
                id="name"
                name="name"
                type="text"
                placeholder="Your Name"
                required
                value={name}
                onChange={onChangeName}
              />
            </Col>
            <Col md={12} className="mb-3">
              <Form.Label>Your Email:</Form.Label>
              <Form.Control
                id="email"
                name="email"
                type="email"
                placeholder="Your Email id"
                required
                value={email}
                onChange={onChangeEmail}
              />
            </Col>
            <Col md={12} className="mb-3">
              <Form.Label>Your Mobile No:</Form.Label>
              <Form.Control
                id="mobile"
                name="mobile"
                type="text"
                placeholder="Your Mobile Number"
                required
                value={mobile}
                onChange={onChangeMobile}
              />
            </Col>
            <Col md={12} className="mb-3">
              <Form.Label>Your Message:</Form.Label>
              <Form.Control
                as="textarea"
                id="message"
                name="message"
                rows="4"
                placeholder="Type your message here..."
                required
                value={message}
                onChange={onChangeMessage}
                style={{ height: '75px' }}
              />
            </Col>
            <Col md={12} className="mb-3">
              <Button type="submit">
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" />
                    {' '}
                    Sending Message
                  </>
                ) : (
                  'Send Message'
                )}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </section>
  );
};
export default ContactUs;
