export const ABOUT_US_DATA = '/aboutus/ABOUT_US_DATA';
export const HOME_DATA = '/home/HOME_DATA';
export const GET_BUSINESS_LISTING_DATA = '/BUSINESS/BUSINESS_LISTING_DATA';
export const GET_INVESTOR_LISTING_DATA = '/INVESTOR/INVESTOR_LISTING_DATA';
export const GET_MENTOR_LISTING_DATA = '/MENTOR/MENTOR_LISTING_DATA';
export const GET_STARTUP_LISTING_DATA = '/STARTUP/STARTUP_LISTING_DATA';
export const GET_LENDER_LISTING_DATA = '/LENDER/LENDER_LISTING_DATA';
export const GET_FRANCHISE_LISTING_DATA = '/FRANCHISE/FRANCHISE_LISTING_DATA';
export const SERVICES_DATA = '/services/SERVICES_DATA';
export const LISTING_DATA = '/listing/LISTING_DATA';
export const REGISTRATION_DATA = '/registration/REGISTRATION_DATA';
export const GET_PROFILE_DATA = '/myprofile/GET_PROFILE_DATA';
export const UPDATE_PROFILE_SUCESS = '/myprofile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = '/myprofile/UPDATE_PROFILE_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const REGISTER_BUSINESS_SUCCESS = 'REGISTER_BUSINESS_SUCCESS';
export const REGISTER_BUSINESS_FAIL = 'REGISTER_BUSINESS_FAIL';
export const REGISTER_CONTACT_US_SUCCESS = 'contactus/REGISTER_CONTACT_US_SUCCESS';
export const REGISTER_CONTACT_US_FAIL = 'contactus/REGISTER_CONTACT_US_FAIL';
