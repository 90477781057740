import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import '../../card_block/Card.css';
import './Services.css';

const Services = () => {
  const services = [
    'Web Development',
    'E-commerce Solutions',
    'App Development',
    'UI/UX Design',
    'SEO Services',
    'Staffing Solutions',
  ];

  return (
    <Container id="services" className="services-section">
      <h2 className="text-center mb-4">Our Solutions</h2>
      <div className="services-card_container">
        {services.map((service, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="card_home alignment-center">
            <div className="card-content alignment-center">{service}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Services;
