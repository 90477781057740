/* eslint-disable max-len */
import contactUsAPI from './contactUsAPI';

import { REGISTER_CONTACT_US_SUCCESS, REGISTER_CONTACT_US_FAIL, SET_MESSAGE } from '../types';

const registerContactUs = (contactUsDetails) => async (dispatch) => contactUsAPI(contactUsDetails).then(
  async (response) => {
    dispatch({
      type: REGISTER_CONTACT_US_SUCCESS,
      payload: response.data,
    });

    dispatch({
      type: SET_MESSAGE,
    });
    return Promise.resolve();
  },
  (error) => {
    const message = (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
        || error.toString();

    dispatch({
      type: REGISTER_CONTACT_US_FAIL,
      payload: { error },
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  },
);
export default registerContactUs;
